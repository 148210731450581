/* eslint-disable @typescript-eslint/no-invalid-this -- this is okay in class properties here */
/* eslint-disable no-underscore-dangle -- using underscores to denote internal properties */
/**
 * @file Syncs a cookie's value with a MobX observable, allowing observers to
 * react when it changes.
 * @author Will Stone
 * @license MIT
 */
// A simple, lightweight JavaScript API for handling browser cookies
import * as jsCookie from 'js-cookie';
// Simple, scalable state management
import { makeAutoObservable } from 'mobx';
const DAY_IN_MS = 86400000;
/**
 * Expires To Milliseconds
 * Internal function to convert a js-cookie expires value to milliseconds.
 */
const expiresToMs = (expires) => {
    if (typeof expires === 'number') {
        return expires * DAY_IN_MS;
    }
    const nowInMs = Date.now();
    return Number(expires) - nowInMs;
};
/**
 * Expires To Date Time
 * Internal function to convert a js-cookie expires value to string date-time.
 */
const expiresToDateTime = (expires) => {
    if (typeof expires === 'number') {
        return new Date(Date.now() + expires * DAY_IN_MS).toString();
    }
    return expires;
};
class MobxCookie {
    constructor(name) {
        this.value = undefined;
        this._timeout = undefined;
        makeAutoObservable(this);
        // name of set cookie
        this._name = name;
        this.value = jsCookie.get(name);
        // Sync Timeout
        // Start timer if an _expires_ cookie exists.
        const expires = jsCookie.get(`${this._name}-expires`);
        if (expires) {
            const ms = expiresToMs(new Date(expires));
            this._timeout = setTimeout(() => this.remove(), ms);
        }
    }
    /**
     * Set
     * Set the value (and optional options) of the cookie. Also starts the
     * removal timer if options.expires is set
     */
    set(value, options = {}) {
        if (this._timeout) {
            clearTimeout(this._timeout);
        }
        this._timeout = undefined;
        this.value = value;
        jsCookie.set(this._name, this.value, options);
        if (options.expires) {
            // set _expires_ cookie, so that timer can be synced on reload.
            const expires = expiresToDateTime(options.expires);
            jsCookie.set(`${this._name}-expires`, expires, {
                expires: options.expires,
            });
            const ms = expiresToMs(options.expires);
            this._timeout = setTimeout(() => this.remove(), ms);
        }
    }
    /**
     * Remove
     * Remove the cookie and reset the observable and timer
     */
    remove() {
        if (this._timeout) {
            clearTimeout(this._timeout);
        }
        this._timeout = undefined;
        this.value = undefined;
        jsCookie.remove(this._name);
        jsCookie.remove(`${this._name}-expires`);
    }
}
export default MobxCookie;
